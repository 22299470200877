import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyANRLOp9ZEW4WiwyofolNx7le99RM1ZwNU',
  authDomain: 'snapguest-3a9fa.firebaseapp.com',
  projectId: 'snapguest-3a9fa',
  storageBucket: 'snapguest-3a9fa.appspot.com',
  messagingSenderId: '355357530749',
  appId: '1:355357530749:web:5ac69edc4fd42752be2f14',
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const messaging = getMessaging(app);

export { auth, db, storage, messaging };
