import React from 'react';
import { Container, Box } from '@mui/material';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth: '1024px',
        padding: '32px',
        margin: '0 auto',
      }}
    >
      <Box>{children}</Box>
    </Container>
  );
};

export default Layout;
