import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';

interface HeaderWithEditButtonProps {
  title: string;
  onClick: () => void;
}

const HeaderWithEditButton: React.FC<HeaderWithEditButtonProps> = ({
  title,
  onClick,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
      width="100%"
    >
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <IconButton onClick={onClick} sx={{ ml: 2 }}>
        <Edit />
      </IconButton>
    </Box>
  );
};

export default HeaderWithEditButton;
