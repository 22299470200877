import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DocImageUploaderProps } from '../types';

const DocImageUploader: React.FC<DocImageUploaderProps> = ({
  frontImageUrl,
  backImageUrl,
  scanning,
  scannedText,
  uploading,
  handleFrontImageChange,
  handleBackImageChange,
  handleUpload,
  scanPassport,
}) => {
  const { t } = useTranslation('translation');

  return (
    <>
      {frontImageUrl && backImageUrl && (
        <Box sx={{ mt: 4, display: 'flex', gap: 2, textAlign: 'left' }}>
          <img
            src={frontImageUrl}
            alt="Front of Passport"
            style={{ width: '100px', height: 'auto' }}
          />
          <img
            src={backImageUrl}
            alt="Back of Passport"
            style={{ width: '100px', height: 'auto' }}
          />
        </Box>
      )}
      <Box sx={{ mt: 4, textAlign: 'left' }}>
        <Typography variant="h5" gutterBottom>
          {t('uploadPassportImages')}
        </Typography>
        <input
          type="file"
          onChange={handleFrontImageChange}
          style={{ display: 'block', marginBottom: '8px' }}
        />
        <Typography variant="body1" gutterBottom>
          {t('passportFrontSide')}
        </Typography>
        <input
          type="file"
          onChange={handleBackImageChange}
          style={{ display: 'block', marginBottom: '8px' }}
        />
        <Typography variant="body1" gutterBottom>
          {t('passportBackSide')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={uploading}
          sx={{ mt: 2, mr: 2 }}
        >
          {uploading ? t('uploading') : t('uploadImages')}
        </Button>

        {frontImageUrl && (
          <Button
            variant="contained"
            color="secondary"
            onClick={scanPassport}
            disabled={scanning}
            sx={{ mt: 2 }}
          >
            {scanning ? t('scanning') : t('scanPassport')}
          </Button>
        )}
      </Box>

      {scannedText && (
        <Box sx={{ mt: 2, textAlign: 'left' }}>
          <Typography variant="h6">{t('scannedData')}</Typography>
          <pre style={{ textAlign: 'left' }}>{scannedText}</pre>
        </Box>
      )}
    </>
  );
};

export default DocImageUploader;
