import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const PaymentForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState<string | null>(null);
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false);

  const handlePaymentSubmit = async () => {
    if (!stripe || !elements) return;

    setPaymentLoading(true);
    setPaymentError(null);

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setPaymentError('Error: Card information is missing.');
      setPaymentLoading(false);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setPaymentError(error.message || 'An unknown error occurred');
      setPaymentLoading(false);
    } else {
      const response = await fetch(
        'https://us-central1-snapguest-3a9fa.cloudfunctions.net/createPaymentIntent',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ paymentMethodId: paymentMethod.id }),
        }
      );

      const data = await response.json();
      setPaymentLoading(false);

      if (data.error) {
        setPaymentError(data.error);
      } else {
        alert('Payment successful!');
      }
    }
  };

  return (
    <Box component="form" noValidate sx={{ mt: 3 }}>
      <CardElement
        options={{
          style: {
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      />
      {paymentError && <Typography color="error">{paymentError}</Typography>}
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={handlePaymentSubmit}
        disabled={!stripe || paymentLoading}
        sx={{ mt: 2 }}
      >
        {paymentLoading ? 'Processing Payment' : 'Pay Now'}
      </Button>

      <Box sx={{ mt: 4, pt: 2 }}>
        <Typography variant="body2" color="textSecondary">
          Example Payment Details:
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
          Card Number: 4242 4242 4242 4242
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Expiry Date: 12/34
        </Typography>
        <Typography variant="body2" color="textSecondary">
          CVC: 123
        </Typography>
        <Typography variant="body2" color="textSecondary">
          ZIP: 12345
        </Typography>
      </Box>
    </Box>
  );
};

export default PaymentForm;
