import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        welcome: 'Welcome to the home page!',
        login: 'Login',
        language: {
          english: 'English',
          slovenian: 'Slovenian',
        },
        loginTitle: 'Login to Your Account',
        bookingReference: 'Booking Reference',
        emailOrPhone: 'Email or Phone Number',
        loginButton: 'Login',
        errorNoReservation: 'No reservation found with the given reference.',
        errorInvalidHolder: 'Invalid holder reference in reservation.',
        errorMismatch:
          'Email or phone number does not match the holder information.',
        errorNotFound: 'Holder not found.',
        errorGeneral: 'An error occurred. Please try again.',
        loading: 'Loading...',
        reservationInformation: 'Reservation Information:',
        property: 'Property',
        checkInDate: 'Check-in Date',
        checkInTime: 'Check-in Time',
        checkOutDate: 'Check-out Date',
        checkOutTime: 'Check-out Time',
        numberOfNights: 'Number of Nights',
        status: 'Status',
        noReservationData: 'No reservation data available.',
        holderInformation: 'Holder Information:',
        name: 'Name',
        surname: 'Surname',
        email: 'Email',
        phoneNumber: 'Phone Number',
        documentType: 'Document Type',
        documentNumber: 'Document Number',
        noHolderData: 'No holder data available.',
        guestInformation: 'Guest Information:',
        gender: 'Gender',
        birthDate: 'Birth Date',
        birthCountry: 'Birth Country',
        nationality: 'Nationality',
        residenceCity: 'Residence City',
        residenceCountry: 'Residence Country',
        taxPaymentCategory: 'Tax Payment Category',
        noGuestData: 'No guest data available.',
        updateDates: 'Update Dates',
        uploadImage: 'Upload Image',
        uploadPassportImages: 'Upload Passport Images',
        passportFrontSide: 'Front Side of Passport',
        passportBackSide: 'Back Side of Passport',
        uploading: 'Uploading...',
        uploadImages: 'Upload Images',
        scanning: 'Scanning...',
        scanPassport: 'Scan Passport',
        scannedData: 'Scanned Data:',
        paymentInformation: 'Payment Information:',
        addGuest: 'Add Guest',
      },
    },
    sl: {
      translation: {
        welcome: 'Dobrodošli na domačo stran!',
        login: 'Prijava',
        language: {
          english: 'Angleščina',
          slovenian: 'Slovenščina',
        },
        loginTitle: 'Prijavite se v svoj račun',
        bookingReference: 'Referenca rezervacije',
        emailOrPhone: 'E-pošta ali telefonska številka',
        loginButton: 'Prijava',
        errorNoReservation: 'Rezervacija s podano referenco ni bila najdena.',
        errorInvalidHolder: 'Neveljavna referenca imetnika v rezervaciji.',
        errorMismatch:
          'E-pošta ali telefonska številka se ne ujema z informacijami imetnika.',
        errorNotFound: 'Imetnik ni bil najden.',
        errorGeneral: 'Prišlo je do napake. Poskusite znova.',
        loading: 'Nalaganje...',
        reservationInformation: 'Informacije o rezervaciji:',
        property: 'Nepremičnina',
        checkInDate: 'Datum prijave',
        checkInTime: 'Čas prijave',
        checkOutDate: 'Datum odjave',
        checkOutTime: 'Čas odjave',
        numberOfNights: 'Število nočitev',
        status: 'Status',
        noReservationData: 'Podatki o rezervaciji niso na voljo.',
        holderInformation: 'Informacije o imetniku:',
        name: 'Ime',
        surname: 'Priimek',
        email: 'E-pošta',
        phoneNumber: 'Telefonska številka',
        documentType: 'Vrsta dokumenta',
        documentNumber: 'Številka dokumenta',
        noHolderData: 'Podatki o imetniku niso na voljo.',
        guestInformation: 'Informacije o gostu:',
        gender: 'Spol',
        birthDate: 'Datum rojstva',
        birthCountry: 'Država rojstva',
        nationality: 'Državljanstvo',
        residenceCity: 'Mesto prebivališča',
        residenceCountry: 'Država prebivališča',
        taxPaymentCategory: 'Kategorija davčnega plačila',
        noGuestData: 'Podatki o gostu niso na voljo.',
        updateDates: 'Posodobi datume',
        uploadImage: 'Naloži sliko',
        uploadPassportImages: 'Naloži slike potnega lista',
        passportFrontSide: 'Sprednja stran potnega lista',
        passportBackSide: 'Zadnja stran potnega lista',
        uploading: 'Nalagam...',
        uploadImages: 'Naloži slike',
        scanning: 'Skeniranje...',
        scanPassport: 'Skeniraj potni list',
        scannedData: 'Skenirani podatki:',
        paymentInformation: 'Informacije o plačilu:',
        addGuest: 'Dodaj gosta',
      },
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
