import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Alert,
  Container,
  Box,
  Typography,
} from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { db } from '../firebase';
import { doc, getDoc, DocumentReference } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';

const schema = yup.object().shape({
  bookingRef: yup.string().required('Booking reference is required'),
  emailOrPhone: yup
    .string()
    .test(
      'emailOrPhone',
      'Please provide a valid email or phone number',
      (value) =>
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value || '') ||
        /^\+?[\d\s\-]{10,15}$/.test(value || '')
    )
    .required('Email or phone number is required'),
});

interface IFormInput {
  bookingRef: string;
  emailOrPhone: string;
}

const Login: React.FC = () => {
  const { t } = useTranslation('translation');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setError(null);

    try {
      const bookingRefTrimmed = data.bookingRef?.trim() || '';
      const emailOrPhoneTrimmed = data.emailOrPhone?.trim() || '';

      const reservationDocRef = doc(db, 'arrayReservations', bookingRefTrimmed);
      const reservationSnapshot = await getDoc(reservationDocRef);

      if (!reservationSnapshot.exists()) {
        setError(t('errorNoReservation'));
        return;
      }

      const reservationData = reservationSnapshot.data();

      const holderRef = reservationData?.holderRef;
      if (!holderRef || !(holderRef instanceof DocumentReference)) {
        setError(t('errorInvalidHolder'));
        return;
      }

      const holderDocRef = doc(db, 'holders', holderRef.id);
      const holderSnapshot = await getDoc(holderDocRef);

      if (holderSnapshot.exists()) {
        const holderData = holderSnapshot.data();

        if (
          holderData.email === emailOrPhoneTrimmed ||
          holderData.tel === emailOrPhoneTrimmed
        ) {
          navigate(`/reservation/?arrayReservationId=${bookingRefTrimmed}`);
        } else {
          setError(t('errorMismatch'));
        }
      } else {
        setError(t('errorNotFound'));
      }
    } catch (err) {
      console.error('Error occurred:', err);
      setError(t('errorGeneral'));
    }
  };

  return (
    <Layout>
      <Container maxWidth="sm">
        <Box sx={{ mt: 8, p: 4, boxShadow: 3 }}>
          <Typography variant="h4" gutterBottom>
            {t('loginTitle')}
          </Typography>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="bookingRef"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('bookingReference')}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.bookingRef}
                  helperText={
                    errors.bookingRef ? t(errors.bookingRef.message || '') : ''
                  }
                />
              )}
            />
            <Controller
              name="emailOrPhone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('emailOrPhone')}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.emailOrPhone}
                  helperText={
                    errors.emailOrPhone
                      ? t(errors.emailOrPhone.message || '')
                      : ''
                  }
                />
              )}
            />
            <Button variant="contained" color="primary" type="submit" fullWidth>
              {t('loginButton')}
            </Button>
          </form>
        </Box>
        <Box sx={{ mt: '20px', textAlign: 'left', boxShadow: 3, p: 4 }}>
          <span>Reservation: IZgP5uWUjjtixBKPIdm2</span>
          <br />
          <span>Mail: Milan3@gmail.com</span>
          <br />
          <span>Phone: +38267354265</span>
        </Box>
      </Container>
    </Layout>
  );
};

export default Login;
