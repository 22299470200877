import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Login from './Login';
import './App.css';

import { messaging } from './firebase';
import { getToken, onMessage } from 'firebase/messaging';

import Reservation from './Reservation';

const App: React.FC = () => {
  // firebase messages

  // useEffect(() => {
  //   const requestPermission = async () => {
  //     console.log('Requesting permission...');
  //     try {
  //       const currentToken = await getToken(messaging, {
  //         vapidKey: 'YOUR_VAPID_KEY',
  //       });
  //       if (currentToken) {
  //         console.log('FCM token:', currentToken);
  //       } else {
  //         console.log(
  //           'No registration token available. Request permission to generate one.'
  //         );
  //       }
  //     } catch (err) {
  //       console.error('An error occurred while retrieving token. ', err);
  //     }
  //   };

  //   requestPermission();

  //   onMessage(messaging, (payload) => {
  //     console.log('Message received. ', payload);
  //   });
  // }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/reservation" element={<Reservation />} />
        </Routes>
      </div>
    </Router>
  );
};

const Home: React.FC = () => (
  <div>
    <h2>Home Page</h2>
  </div>
);

export default App;
