// src/components/HolderFormFields.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { HolderData } from '../types';

interface HolderFormFieldsProps {
  holderData: HolderData;
  handleFieldChange: (field: keyof HolderData, value: string) => void;
  isEditing: boolean;
  onSave: () => void;
  onCancel: () => void;
}

const HolderFormFields: React.FC<HolderFormFieldsProps> = ({
  holderData,
  handleFieldChange,
  isEditing,
  onSave,
  onCancel,
}) => {
  const { t } = useTranslation('translation');

  const fields = [
    { label: 'name', value: holderData.name, key: 'name' as keyof HolderData },
    {
      label: 'surname',
      value: holderData.surname,
      key: 'surname' as keyof HolderData,
    },
    {
      label: 'email',
      value: holderData.email,
      key: 'email' as keyof HolderData,
    },
    {
      label: 'phoneNumber',
      value: holderData.tel,
      key: 'tel' as keyof HolderData,
    },
    {
      label: 'documentType',
      value: holderData.documentType,
      key: 'documentType' as keyof HolderData,
    },
    {
      label: 'documentNumber',
      value: holderData.documentNumber,
      key: 'documentNumber' as keyof HolderData,
    },
  ];

  return (
    <>
      {fields.map((field) => (
        <div
          key={field.key}
          style={{ marginBottom: '16px', textAlign: 'left' }}
        >
          {isEditing ? (
            <TextField
              label={t(field.label)}
              value={field.value}
              onChange={(e) => handleFieldChange(field.key, e.target.value)}
              fullWidth
            />
          ) : (
            <div>
              <strong>{t(field.label)}:</strong> {field.value}
            </div>
          )}
        </div>
      ))}

      {isEditing && (
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={onSave}>
            {t('save')}
          </Button>
          <Button variant="outlined" color="secondary" onClick={onCancel}>
            {t('cancel')}
          </Button>
        </Stack>
      )}
    </>
  );
};

export default HolderFormFields;
