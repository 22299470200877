import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DocImageUploader from './DocImageUploader';
import CalendarForm from './CalendarForm';
import { FormProvider, useForm } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { DocImageUploaderProps, GuestData } from '../types';

interface GuestFormFieldsProps extends DocImageUploaderProps {
  guestData: GuestData;
  handleFieldChange: (field: keyof GuestData, value: string) => void;
  isEditing: boolean;
  onSave: () => void;
  onCancel: () => void;
}

const GuestFormFields: React.FC<GuestFormFieldsProps> = ({
  guestData,
  handleFieldChange,
  isEditing,
  onSave,
  onCancel,
  frontImageUrl,
  backImageUrl,
  scanning,
  scannedText,
  uploading,
  handleFrontImageChange,
  handleBackImageChange,
  handleUpload,
  scanPassport,
}) => {
  const { t } = useTranslation('translation');

  const methods = useForm({
    defaultValues: {
      checkInDate: guestData.checkInDate,
      checkInTime: guestData.checkInTime,
      checkOutDate: guestData.checkOutDate,
      checkOutTime: guestData.checkOutTime,
    },
  });

  const fields = [
    { label: 'name', value: guestData.name },
    { label: 'surname', value: guestData.surname },
    { label: 'gender', value: guestData.gender },
    { label: 'birthDate', value: guestData.birthDate },
    { label: 'birthCountry', value: guestData.birthCountry },
    { label: 'nationality', value: guestData.nationality },
    { label: 'residenceCity', value: guestData.residenceCity },
    { label: 'residenceCountry', value: guestData.residenceCountry },
    { label: 'documentType', value: guestData.documentType },
    { label: 'documentNumber', value: guestData.documentNumber },
    { label: 'taxPaymentCategory', value: guestData.taxPaymentCategory },
    { label: 'checkInDate', value: guestData.checkInDate },
    { label: 'checkInTime', value: guestData.checkInTime },
    { label: 'checkOutDate', value: guestData.checkOutDate },
    { label: 'checkOutTime', value: guestData.checkOutTime },
  ];

  return (
    <div style={{ textAlign: 'left' }}>
      {fields.map((field) => (
        <div key={field.label} style={{ marginBottom: '16px' }}>
          {isEditing ? (
            <TextField
              label={t(field.label)}
              value={field.value}
              onChange={(e) =>
                handleFieldChange(
                  field.label as keyof GuestData,
                  e.target.value
                )
              }
              fullWidth
            />
          ) : (
            <div>
              <strong>{t(field.label)}:</strong> {field.value}
            </div>
          )}
        </div>
      ))}
      {/*
      {isEditing && (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <FormProvider {...methods}>
            <CalendarForm guestData={guestData} onSubmit={onSave} />
          </FormProvider>
        </LocalizationProvider>
      )} */}

      {isEditing && (
        <DocImageUploader
          frontImageUrl={frontImageUrl}
          backImageUrl={backImageUrl}
          scanning={scanning}
          scannedText={scannedText}
          uploading={uploading}
          handleFrontImageChange={handleFrontImageChange}
          handleBackImageChange={handleBackImageChange}
          handleUpload={handleUpload}
          scanPassport={scanPassport}
        />
      )}

      {isEditing && (
        <Stack direction="row" spacing={2} sx={{ textAlign: 'left' }}>
          <Button variant="contained" color="primary" onClick={onSave}>
            {t('save')}
          </Button>
          <Button variant="outlined" color="secondary" onClick={onCancel}>
            {t('cancel')}
          </Button>
        </Stack>
      )}
    </div>
  );
};

export default GuestFormFields;
