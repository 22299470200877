import OneSignal from 'react-onesignal';

const OneSignalInit = async () => {
  await OneSignal.init({
    appId: 'c253cfb3-f194-4426-a45a-102a81c0216a',
    notifyButton: {
      enable: true,
    },
  });
  OneSignal.showSlidedownPrompt();
};

export default OneSignalInit;
