// src/Reservation.tsx
import React, { useEffect, useState, Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';
import { db, storage } from '../firebase';
import {
  doc,
  getDoc,
  updateDoc,
  DocumentReference,
  addDoc,
  collection,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  Divider,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import imageCompression from 'browser-image-compression';
import Tesseract from 'tesseract.js';
import GuestFormFields from '../components/GuestFormFields';
import HolderFormFields from '../components/HolderFormFields';
import DocImageUploader from '../components/DocImageUploader';
import PaymentForm from '../components/PaymentForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { GuestData, HolderData, ReservationData } from '../types';
import Layout from '../components/Layout';
import CalendarForm from '../components/CalendarForm';
import HeaderWithEditButton from '../components/HeaderWithEditButton';
import OneSignal from 'react-onesignal';
import OneSignalInit from '../OneSignalInit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const stripeKey: string = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
const stripePromise = loadStripe(stripeKey);

// one signal

const sendTagToOneSignal = async () => {
  const response = await fetch('https://onesignal.com/api/v1/players', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'ZjI2ZDc3YWItYWZlNC00MTA0LThjZTYtZmQzNTgxOGU4MmU5',
    },
    body: JSON.stringify({
      app_id: 'c253cfb3-f194-4426-a45a-102a81c0216a',
      tags: {
        reservationConfirmed: 'true',
      },
      id: 'PLAYER_ID',
    }),
  });

  if (response.ok) {
    console.log('Tag sent successfully');
  } else {
    console.error('Failed to send tag');
  }
};

// twilio

interface SendWhatsAppMessageResult {
  success: boolean;
  messageSid?: string;
  error?: string;
}

const sendWhatsAppNotification = async (
  toPhoneNumber: string,
  messageBody: string
) => {
  try {
    const response = await fetch(
      'https://us-central1-snapguest-3a9fa.cloudfunctions.net/sendWhatsAppMessage',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ to: toPhoneNumber, message: messageBody }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    if (result.success) {
      console.log('Message sent successfully: ', result.messageSid);
    } else {
      console.error('Failed to send message: ', result.error);
    }
  } catch (error) {
    console.error('Error calling cloud function: ', error);
    throw error;
  }
};

const ReservationContent = () => {
  const { t } = useTranslation('translation');
  const methods = useForm();
  const { control, handleSubmit, setValue } = methods;
  const [reservationData, setReservationData] =
    useState<ReservationData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [frontImage, setFrontImage] = useState<File | null>(null);
  const [backImage, setBackImage] = useState<File | null>(null);
  const [frontImageUrl, setFrontImageUrl] = useState<string | null>(null);
  const [backImageUrl, setBackImageUrl] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [scanning, setScanning] = useState<boolean>(false);
  const [scannedText, setScannedText] = useState<string | null>(null);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const arrayReservationId = searchParams.get('arrayReservationId');
  const [accessCode, setAccessCode] = useState<string | null>(null);

  const [editingGuestIndex, setEditingGuestIndex] = useState<number | null>(
    null
  );
  const [guestData, setGuestData] = useState<GuestData[]>([]);
  const [newGuests, setNewGuests] = useState<GuestData[]>([]);
  const [isEditingNewGuest, setIsEditingNewGuest] = useState<boolean[]>([]);
  const [holderData, setHolderData] = useState<HolderData | null>(null);
  const [isEditingHolder, setIsEditingHolder] = useState<boolean>(false);

  useEffect(() => {
    if (arrayReservationId) {
      fetchReservationData(arrayReservationId).finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [arrayReservationId]);

  useEffect(() => {
    const fetchAccessCode = async () => {
      if (arrayReservationId) {
        const reservationDocRef = doc(
          db,
          'arrayReservations',
          arrayReservationId
        );
        const reservationSnapshot = await getDoc(reservationDocRef);
        if (reservationSnapshot.exists()) {
          const data = reservationSnapshot.data();
          setAccessCode(data.accessCode || null);
        }
      }
    };

    fetchAccessCode().finally(() => setLoading(false));
  }, [arrayReservationId]);

  const fetchReservationData = async (id: string) => {
    try {
      const reservationDocRef = doc(db, 'arrayReservations', id);
      const reservationSnapshot = await getDoc(reservationDocRef);

      if (reservationSnapshot.exists()) {
        const reservation = reservationSnapshot.data() as ReservationData;
        setReservationData(reservation);

        if (reservation.guests && Array.isArray(reservation.guests)) {
          const uniqueGuestData = new Set<GuestData>();
          const guestPromises = reservation.guests.map(async (guestRef) => {
            if (
              guestRef &&
              typeof guestRef === 'object' &&
              'path' in guestRef
            ) {
              const guestData = await fetchGuestData(
                guestRef as DocumentReference
              );
              if (guestData) {
                uniqueGuestData.add(guestData);
              }
            }
          });

          await Promise.all(guestPromises);
          setGuestData(Array.from(uniqueGuestData));
        }

        if (reservation.holderRef) {
          await fetchHolderData(reservation.holderRef);
        }

        setValue('checkInDate', moment(reservation.checkInDate));
        setValue('checkOutDate', moment(reservation.checkOutDate));
      }
    } catch (error) {
      console.error('Error fetching reservation data:', error);
    }
  };

  const fetchHolderData = async (holderRef: DocumentReference) => {
    try {
      const holderSnapshot = await getDoc(holderRef);
      if (holderSnapshot.exists()) {
        const holder = holderSnapshot.data() as HolderData;
        setHolderData(holder);
        if (holder.passportFrontImageUrl) {
          setFrontImageUrl(holder.passportFrontImageUrl);
        }
        if (holder.passportBackImageUrl) {
          setBackImageUrl(holder.passportBackImageUrl);
        }
      } else {
        console.error('Holder data not found for reference:', holderRef.path);
      }
    } catch (error) {
      console.error('Error fetching holder data:', error);
    }
  };

  const fetchGuestData = async (
    guestRef: DocumentReference
  ): Promise<GuestData | null> => {
    try {
      const guestSnapshot = await getDoc(guestRef);
      if (guestSnapshot.exists()) {
        const guest = guestSnapshot.data() as GuestData;
        return guest;
      }
      return null;
    } catch (error) {
      console.error('Error fetching guest data:', error);
      return null;
    }
  };

  const handleFieldChange = (
    guestIndex: number,
    field: keyof GuestData,
    value: string
  ) => {
    setGuestData((prevGuestData) =>
      prevGuestData.map((guest, index) =>
        index === guestIndex ? { ...guest, [field]: value } : guest
      )
    );
  };

  const onSubmit = async (data: any) => {
    try {
      if (!reservationData) return;

      const reservationDocRef = doc(
        db,
        'arrayReservations',
        arrayReservationId!
      );
      await updateDoc(reservationDocRef, {
        checkInDate: moment(data.checkInDate).format('YYYY-MM-DD'),
        checkOutDate: moment(data.checkOutDate).format('YYYY-MM-DD'),
      });
      alert('Dates updated successfully!');
    } catch (error) {
      console.error('Error updating dates:', error);
      alert('Failed to update dates.');
    }
  };

  const handleFrontImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFrontImage(e.target.files[0]);
    }
  };

  const handleBackImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setBackImage(e.target.files[0]);
    }
  };

  const compressImage = async (file: File): Promise<File> => {
    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error('Error compressing image:', error);
      throw error;
    }
  };

  const handleUpload = async () => {
    if (
      (!frontImage && !frontImageUrl) ||
      (!backImage && !backImageUrl) ||
      !holderData
    ) {
      alert(
        'Please select both images and make sure the holder data is loaded.'
      );
      return;
    }

    setUploading(true);

    try {
      let newFrontImageUrl = frontImageUrl;
      let newBackImageUrl = backImageUrl;

      if (frontImage) {
        const compressedFrontImage = await compressImage(frontImage);
        const frontImageRef = ref(
          storage,
          `passports/front_${compressedFrontImage.name}`
        );
        await uploadBytes(frontImageRef, compressedFrontImage);
        newFrontImageUrl = await getDownloadURL(frontImageRef);
      }

      if (backImage) {
        const compressedBackImage = await compressImage(backImage);
        const backImageRef = ref(
          storage,
          `passports/back_${compressedBackImage.name}`
        );
        await uploadBytes(backImageRef, compressedBackImage);
        newBackImageUrl = await getDownloadURL(backImageRef);
      }

      const holderRefId = reservationData!.holderRef.id;
      const holderDocRef = doc(db, 'holders', holderRefId);
      await updateDoc(holderDocRef, {
        passportFrontImageUrl: newFrontImageUrl,
        passportBackImageUrl: newBackImageUrl,
      });

      setFrontImageUrl(newFrontImageUrl);
      setBackImageUrl(newBackImageUrl);

      alert('Images uploaded successfully, and URLs saved!');
    } catch (error: any) {
      console.error('Error uploading images:', error);
      alert(`Failed to upload images: ${error.message || error}`);
    } finally {
      setUploading(false);
    }
  };

  const scanPassport = async () => {
    if (!frontImageUrl) {
      alert('Please upload the front side of the passport before scanning.');
      return;
    }

    setScanning(true);
    try {
      const result = await Tesseract.recognize(frontImageUrl, 'eng');
      setScannedText(result.data.text);
    } catch (error) {
      console.error('Error scanning passport:', error);
      alert('Failed to scan passport.');
    } finally {
      setScanning(false);
    }
  };

  const handleEditHolderClick = () => {
    setIsEditingHolder(true);
  };

  const handleSaveHolder = () => {
    setIsEditingHolder(false);
  };

  const handleCancelHolder = () => {
    setIsEditingHolder(false);
  };

  const handleAddGuest = () => {
    const newGuest: GuestData = {
      birthCountry: '',
      birthDate: '',
      documentNumber: '',
      documentType: '',
      gender: '',
      name: '',
      nationality: '',
      residenceCity: '',
      residenceCountry: '',
      surname: '',
      taxPaymentCategory: '',
      checkInDate: '',
      checkInTime: '',
      checkOutDate: '',
      checkOutTime: '',
    };

    setNewGuests((prevNewGuests) => [...prevNewGuests, newGuest]);
    setIsEditingNewGuest((prev) => [...prev, true]);
  };
  const handleSaveNewGuest = (index: number) => {
    setIsEditingNewGuest((prev) =>
      prev.map((editing, i) => (i === index ? false : editing))
    );
  };

  const handleEditNewGuest = (index: number) => {
    setIsEditingNewGuest((prev) =>
      prev.map((editing, i) => (i === index ? true : editing))
    );
  };

  const handleEditGuestClick = (index: number) => {
    setEditingGuestIndex(index);
  };

  const handleSaveGuest = () => {
    setEditingGuestIndex(null);
  };

  const handleCancelGuest = () => {
    setEditingGuestIndex(null);
  };

  useEffect(() => {
    OneSignalInit();
  }, []);

  const handleConfirm = async () => {
    if (!isChecked) {
      alert('Please agree to the terms and conditions before confirming.');
      return;
    }

    try {
      if (
        guestData.length > 0 &&
        reservationData?.guests &&
        arrayReservationId
      ) {
        await Promise.all(
          guestData.map(async (guest, index) => {
            const guestDocRef = reservationData.guests[index];
            await updateDoc(guestDocRef, { ...guest });
          })
        );
      }

      if (newGuests.length > 0 && arrayReservationId) {
        await Promise.all(
          newGuests.map(async (newGuest) => {
            const guestsCollectionRef = collection(db, 'guests');
            const newGuestRef = await addDoc(guestsCollectionRef, {
              ...newGuest,
            });
            const reservationDocRef = doc(
              db,
              'arrayReservations',
              arrayReservationId
            );
            await updateDoc(reservationDocRef, {
              guests: [...(reservationData?.guests || []), newGuestRef],
            });
          })
        );
      }

      if (holderData && reservationData) {
        const holderRefId = reservationData.holderRef.id;
        const holderDocRef = doc(db, 'holders', holderRefId);
        await updateDoc(holderDocRef, { ...holderData });
      }

      if (arrayReservationId) {
        const reservationDocRef = doc(
          db,
          'arrayReservations',
          arrayReservationId!
        );
        await updateDoc(reservationDocRef, {
          status: 'confirmed',
        });

        // await sendTagToOneSignal();

        try {
          await sendWhatsAppNotification(
            '+38267354265',
            'Your reservation has been confirmed!'
          );
          console.log('WhatsApp notification sent successfully');
        } catch (whatsappError) {
          console.error('Failed to send WhatsApp notification:', whatsappError);
        }

        alert(
          'Guest and holder data updated successfully, and reservation confirmed!'
        );
      } else {
        console.error('No reservation ID found.');
      }
    } catch (error) {
      console.error('Error updating data:', error);
      alert('Failed to update data.');
    }
  };

  return (
    <main className="flex min-h-screen p-24">
      {loading ? (
        <p>{t('loading')}</p>
      ) : (
        <div>
          {/* holder */}

          <section id="holder-section">
            {holderData ? (
              <>
                <HeaderWithEditButton
                  title={t('holderInformation')}
                  onClick={handleEditHolderClick}
                />

                <HolderFormFields
                  holderData={holderData}
                  handleFieldChange={(field, value) =>
                    setHolderData((prev) =>
                      prev ? { ...prev, [field]: value } : null
                    )
                  }
                  isEditing={isEditingHolder}
                  onSave={handleSaveHolder}
                  onCancel={handleCancelHolder}
                />
                {/*
                <DocImageUploader
                  frontImageUrl={frontImageUrl}
                  backImageUrl={backImageUrl}
                  scanning={scanning}
                  scannedText={scannedText}
                  uploading={uploading}
                  handleFrontImageChange={handleFrontImageChange}
                  handleBackImageChange={handleBackImageChange}
                  handleUpload={handleUpload}
                  scanPassport={scanPassport}
                /> */}
              </>
            ) : (
              <p>{t('noHolderData')}</p>
            )}
          </section>

          {/* end of holder */}

          <Divider sx={{ my: 4 }} />

          {/* guest */}

          <section id="guests-section">
            {guestData.length > 0 ? (
              guestData.map((guest, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`guest-panel-${index}-content`}
                    id={`guest-panel-${index}-header`}
                  >
                    <Typography>
                      {guest.name} {guest.surname}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <HeaderWithEditButton
                      title={t('guestInformation')}
                      onClick={() => handleEditGuestClick(index)}
                    />
                    <GuestFormFields
                      guestData={guest}
                      isEditing={editingGuestIndex === index}
                      handleFieldChange={(field, value) =>
                        handleFieldChange(index, field, value)
                      }
                      onSave={handleSaveGuest}
                      onCancel={handleCancelGuest}
                      frontImageUrl={frontImageUrl}
                      backImageUrl={backImageUrl}
                      scanning={scanning}
                      scannedText={scannedText}
                      uploading={uploading}
                      handleFrontImageChange={handleFrontImageChange}
                      handleBackImageChange={handleBackImageChange}
                      handleUpload={handleUpload}
                      scanPassport={scanPassport}
                    />
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <p>{t('noGuestData')}</p>
            )}
          </section>

          {/* end of guest */}

          {/* new guest */}

          <section id="new-guest-section">
            {newGuests.map((guest, index) => (
              <div key={`new-${index}`}>
                <Typography variant="h5" gutterBottom>
                  {t('newGuestInformation')} {index + 1}
                </Typography>
                <GuestFormFields
                  guestData={guest}
                  isEditing={isEditingNewGuest[index]}
                  handleFieldChange={(field, value) =>
                    setNewGuests((prevNewGuests) =>
                      prevNewGuests.map((g, i) =>
                        i === index ? { ...g, [field]: value } : g
                      )
                    )
                  }
                  onSave={() => handleSaveNewGuest(index)}
                  onCancel={() =>
                    setNewGuests((prev) => prev.filter((_, i) => i !== index))
                  }
                  frontImageUrl={frontImageUrl}
                  backImageUrl={backImageUrl}
                  scanning={scanning}
                  scannedText={scannedText}
                  uploading={uploading}
                  handleFrontImageChange={handleFrontImageChange}
                  handleBackImageChange={handleBackImageChange}
                  handleUpload={handleUpload}
                  scanPassport={scanPassport}
                />
                <Divider sx={{ my: 4 }} />
              </div>
            ))}

            <Button
              variant="contained"
              color="secondary"
              onClick={handleAddGuest}
              sx={{ mt: 2 }}
            >
              {t('addGuest')}
            </Button>
          </section>

          {/* end of new guest */}

          <Divider sx={{ my: 4 }} />

          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
                color="primary"
              />
            }
            label="I have read and agree to the terms and conditions"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={!isChecked}
            sx={{ mt: 2, fontSize: '1.2rem', padding: '10px 20px' }}
          >
            Confirm
          </Button>

          <Divider sx={{ my: 4 }} />

          {/* Strapi */}

          <section id="payment">
            <Typography variant="h5" gutterBottom>
              {t('paymentInformation')}
            </Typography>
            <PaymentForm />
          </section>

          {/* End of Strapi */}
        </div>
      )}
    </main>
  );
};

export default function Reservation() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Elements stripe={stripePromise}>
        <Suspense fallback={<div>Loading...</div>}>
          <Layout>
            <ReservationContent />
          </Layout>
        </Suspense>
      </Elements>
    </LocalizationProvider>
  );
}
